import React, { useState } from 'react';

const Portfolio = () => {

	const [displayView, setDisplayView] = useState('section-digital');
	const [displayWeb, setDisplayWeb] = useState(true);
	const handleClick = (e)=>{
		if(e.target.getAttribute('data-href') ==='section-digital'){
			setDisplayWeb(true)
		}else{
			setDisplayWeb(false)
		}
		setDisplayView(e.target.getAttribute('data-href'))
	}

    return (
            <section id= "portfolio" className=''>
				<header className="gyo-header"><h1>Portfolio</h1></header>
				<div>
					<ul className='portfolio-type'>
						<li><h2><a className={`subtitle ${!displayWeb?'active':''}`} data-href="section-illustation" onClick={handleClick}>Illustrations, peintures</a></h2></li>
						<li><h2><a className={`subtitle ${displayWeb?'active':''}`} data-href="section-digital" onClick={handleClick}>Print / Web</a></h2></li>
					</ul>
					<div id='content-block'>

						{displayWeb?

						<div className='section-digital'>
							<section id="portftolio-web">
								<div className="portfolio-item">
									<a className="fancybox" href="assets/img/prestations/web/vignette_g1.jpg"  rel="gallery1" ><img src="assets/img/prestations/web/vignette_s1.jpg" height='140' alt="www.baleines a bec.com" title="www.baleines a bec.com"/></a>
									<span className="item-caption">www.baleines a bec.com</span>
								</div>
								<div className="portfolio-item">
									<a className="fancybox" href="assets/img/prestations/web/vignette_g2.jpg" rel="gallery1"><img src="assets/img/prestations/web/vignette_s2.jpg" height='140' alt="www.coma-manga.com" title="www.coma-manga.com"/></a>
									<span className="item-caption">www.coma-manga.com</span>
								</div>
								<div className="portfolio-item">
									<a className="fancybox" href="assets/img/prestations/web/vignette_g3.jpg" rel="gallery1"><img src="assets/img/prestations/web/vignette_s3.jpg" alt="www.odic-consulting-management.com" title="www.odic-consulting-management.com" height='140'/></a>
									<span className="item-caption">www.odic-consulting-management.com</span>
								</div>
							</section>
							<section id="portfolio-print">
								<div className="portfolio-item">
									<a className="fancybox" href="assets/img/prestations/print/vignette_g1.jpg"  rel="gallery1" ><img  alt="Flyer publicitaire pour un prestataire dans le secteur du Bâtiment" title="Flyer publicitaire pour un prestataire dans le secteur du Bâtiment" src="assets/img/prestations/print/vignette_s1.jpg" height='140'/></a>
									<span className="item-caption">Flyer publicitaire pour un prestataire dans le secteur du Bâtiment</span>
								</div>
								<div className="portfolio-item">
									<a className="fancybox" href="assets/img/prestations/print/vignette_g2.jpg" rel="gallery1"><img alt="Revue de presse pour le Journal du Japon" title="Revue de presse pour le Journal du Japon" src="assets/img/prestations/print/vignette_s2.jpg" height='140'/></a>
									<span className="item-caption">Revue de presse pour le Journal du Japon</span>
								</div>
								<div className="portfolio-item">
									<a className="fancybox" href="assets/img/prestations/print/vignette_g3.jpg" rel="gallery1"><img alt="Couverture d'album pour un artiste de Rap: VESP" title="Couverture d'album pour un artiste de Rap: VESP" src="assets/img/prestations/print/vignette_s3.jpg" height='140'/></a>
									<span className="item-caption">Couverture d'album pour un artiste de Rap: VESP</span>
								</div>
							</section>
						</div>:
						<div className='section-illustration'>
							<section id="portfolio-drawings">
								<iframe src="//lightwidget.com/widgets/5eb03a1a37875d978afab77858124234.html"  title='Portfolio gyo' scrolling="no" allowtransparency="true" className="lightwidget-widget" style={{width:'100%',border:0,overflow:'hidden'}}></iframe>		
							</section>
						</div>
						}
					</div>
				</div>
			</section>

    );
}

export default Portfolio;