import React from 'react';
import { Link } from 'react-router-dom';

function ErrorPage(props) {
    return (
        <div>
            <h1>La page n'a pas été trouvée!</h1>
            <p>Veuillez saisir un autre recherche depuis le navigateur ou retourner  à l'accueil</p>
            <Link to='/'>Retour à l'accueil</Link>
        </div>
    );
}

export default ErrorPage;