import React from 'react';
import { Link } from 'react-router-dom';
import webdesign from '../assets/img/prestations/webdesign.svg';
import illustration from '../assets/img/prestations/illustration.svg';
import tailormade from '../assets/img/prestations/tailormade.svg';


const Prestations = ()=> {
    return (
		<div id='prestations' className=''>
			<article className="a_leftToRight prestation-item">
				<img src={webdesign} alt='Infographie & intégration web' title='Infographie & intégration web'/>
				<h2>INFOGRAPHIE / WEBDESIGN</h2>
				<p>"Dessinons et tissons votre toile! Véhiculons votre image!"</p>
				<ul>
					<li>Chartes graphiques / Intégration / Conception de sites vitrines,</li>
					<li>Développement Front-end (Angular, React, vanilla JS)</li>
					<li>Supports de communication (Flyers, Posters, Bannières, Logos, Cartes de visites, Dépliants) </li>
				</ul>
				<Link to="about" className='btn'>En savoir plus</Link>
			</article>
			<article className="a_fadeIn prestation-item">
				<img src={illustration} alt='Nous réalisons vos illustrations' title='Nous réalisons vos illustrations'/>
				<h2>ILLUSTRATION</h2>
				<p>Divers styles: Réaliste, Mangas, Comics & Franco-belge</p>
				<ul>
					<li>Colorisation traditionnelle:  Peinture à l'huile, aquarelle et Pantone</li>
					<li>Digital Painting/pixel art</li>
					<li>Bande dessinée <a target='_blank' rel="noopener noreferrer" href="http://www.coma-manga.com">(COMA)</a></li>
					<li>Portraits</li>
				</ul>
				<a className='btn' href ="http://www.jnicolas.art" target='_blank' rel="noopener noreferrer" >Voir le site dédié</a>
			</article>
			<article className="a_rightToLeft prestation-item">
				<img src={tailormade} alt='Vos idées sur mesure' title='Vos idées sur mesure'/>
				<h2>VOTRE PROJET PERSONNALISE</h2>
				<p>Vos idées sur mesure</p>
				<ul>
					<li>Une demande particulière? Vous souhaitez la partager ?</li>
				</ul>
				<Link to="/contact" className='btn'>Dirigez vous vers la rubrique <strong>contact</strong></Link>
			</article>
		</div>
    );
}

export default Prestations;