import React from 'react';
import logoGyo from '../assets/img/profile/logo-gyo.svg';
import logoJnicolas from '../assets/img/profile/logo-jnicolas.svg';
import insta from '../assets/img/icons/instagram.svg';
import fb from '../assets/img/icons/fb.svg';
import cv from '../assets/img/icons/cv.svg';
import git from '../assets/img/icons/github.svg';
import linkedin from '../assets/img/icons/linkedin.svg';


const About=()=> {
    return (
            <div id="about" className=''>
				<h1>A propos</h1>
				<article>
					<figure className = "banner-article">
						<img src={logoGyo} alt='gyo aka jnicolas' title='gyo aka jnicolas'/>
						<p>aKa</p>
						<img src={logoJnicolas} className='w-50' alt='gyo aka jnicolas' title='gyo aka jnicolas'/>
						<div className = "banner-article-layout"></div>
						<p id="about-quotation">
							<span className='txt-citation'>“Depuis tout petit, je vis au contact du monde de l'Image.”</span><br/>De manière générale, j'aime créer et je considère la création comme un véritable "pouvoir" et c'est
							pourquoi je me suis fixé un but; cumuler les compétences d'Illustrateur, Infographiste et Développeur front-End.<br/>
							C'est ce que je réalise aujourd'hui en tant que professionnel du web et du graphisme depuis plus de 3 ans, mais aussi en tant que passionné pour la bande dessinée, la peinture et l'illustration que je réalise en autodidacte.<br/><br/>
							Mon expérience m'a permis de travailler en tant que freelance au et en tant que consultant au sein de SSII, seul ou en intégrant des équipes au sein d'entreprises dans différent secteurs (Finance, Marketing, Société éditrice de logiciels ou Agence de communication).
							<br/><br/><strong>Et prochainement, pourquoi pas  avec vous?</strong>
						</p>
					</figure>
					<div>
						<ul id="about-navigation">
							<li><a className="about" href="https://www.linkedin.com/in/jcnicolas/" target='_blank' rel="noopener noreferrer" ><img src={linkedin} className='w-50' alt='linkedin' title='Mon reseau linkedin'/></a></li>
							<li><a className="about" href="assets/img/profile/cv.pdf" target='_blank' rel="noopener noreferrer" ><img src={cv} className='w-50' alt='cv' title='Consuilter mon cv'/></a></li>
							<li><a className="about" href="https://fr-fr.facebook.com/pages/Gyo-Sue/213931091956382" target='_blank' rel="noopener noreferrer" ><img src={fb} className='w-50' alt='facebook de gyo sue' title='Rejoignez moi sur Facebook'/></a></li>
							<li><a className="about" href="https://instagram.com/jeeneekola" target='_blank' rel="noopener noreferrer" ><img src={insta} className='w-50' alt='Profil Instagram de J Nicolas' title='Suivre jeeneekola sur instagram'/></a></li>
							<li><a className="about" href="https://github.com/yashowa" target='_blank' rel="noopener noreferrer" ><img src={git} className='w-50' alt='github' title='Visiter mon github'/></a></li>
						</ul>
					</div>
				</article>
			</div>
    )
}

export default About;