import React  from 'react';
import Menu from './Menu';

const header = ()=> {
    return (
        <div className='content'>
            <Menu/>
        </div>
    );
}

export default header;