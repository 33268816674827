import './App.css';
import './assets/css/style.css';
import Header from './components/global/Header';
import Footer from './components/global/Footer';


import { BrowserRouter} from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
          <Header/>
          <Footer/>
      </div>
    </BrowserRouter>
  
  );
}

export default App;
