import React from 'react';

const PrivacyPolicy = (props) => {
    return (
        <div className='content'>
            <h1>Politique de confidentialité et protection des données personnelles</h1>

                <p> Cette présente politique de protection des données personnelles s’adresse à chaque utilisateur du site gyo-webgraphiste.com.

                Le site gyo-webgraphiste.com publie des informations sur ses prestations. Le suivi éditorial, graphique et technique du site est assuré au quotidien par le webmaster du site.


                En sa qualité de responsable de traitement, le Ministère de l’Europe et des Affaires étrangères s’engage à assurer le respect de votre vie privée et à protéger les données à caractère personnel traitées :

                De manière licite, loyale et transparente au regard de la personne concernée ;
                Collectées pour des finalités déterminées, explicites et légitimes ;
                Adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées ;
                Traitées de façon à garantir une sécurité appropriée des données à caractère personnel.
                </p>


                <h3>Définitions</h3>


                <h4>1. Donnée à caractère personnel (donnée personnelle)</h4>
                Article 4.1 du RGPD : Une donnée à caractère personnel est une information relative à une personne physique susceptible d’être identifiée, directement ou indirectement.<br/><br/>

                <h4>2. Droits des personnes concernées</h4>
                Article 12 du RGPD  : Les personnes concernées disposent, sur leurs données, de droits dédiés tels qu’un droit d’accès, de rectification, d’opposition, de limitation, d’effacement et de portabilité de vos données personnelles.<br/><br/>

                <h4>3. Règlementation sur la Protection des données personnelles</h4>
                Désigne le corpus juridique relatif à la protection des données personnelles, comprenant la Loi° 78-17 du 6 janvier 1978 modifiée relative à l’informatique, aux fichiers et aux libertés, modifiée et toute nouvelle loi, décrets pris pour son application, ainsi que le Règlement Général sur la Protection des données (RGPD), référence en matière de protection des données personnelles, entré en application le 25 mai 2018.<br/><br/>

                <h4>4. Responsable de traitement</h4>
                Article 4.7 du RGPD : Un responsable de traitement est la personne physique ou morale, l’autorité publique, le service ou autre organisme qui, seul ou conjointement avec d’autres, détermine les finalités et les moyens du traitement.<br/><br/>

                Politique de confidentialité et de protection de la vie privée
                Conformément à la Règlementation sur la protection des données personnelles, les traitements décrits dans la présente Politique de Confidentialité et Protection de la Vie Privée sont soutenus par une base légale spécifique.<br/><br/>

                La présente Politique vous renseigne sur les conditions dans lesquelles le Ministère de l’Europe et des Affaires Etrangères collecte, traite, conserve, archive et supprime vos données personnelles. Elle vous informe également sur les droits dont vous disposez sur vos données.


                <h3>Quels sont les finalités et les fondements justifiant la collecte de vos données personnelles ?</h3>

                <h4>Système de journalisation (Intérêt légitime)</h4>
                Permettre d’identifier un accès frauduleux ou une utilisation abusive de données personnelles, ou de déterminer l’origine d’un incident, nous enregistrons certaines des actions effectuées sur les systèmes informatiques. Pour ce faire, France Diplomatie dispose d’un dispositif de gestion des traces et des incidents. Celui-ci enregistre les évènements pertinents et garantit que ces enregistrements ne peuvent être altérés.

                <h4>Formulaire de contact (Consentement)</h4>
                Permettre à tout utilisateur de nous contacter, concernant une question particulière pouvant l’intéresser.

        </div>
    );
}

export default PrivacyPolicy;