import React, {useState } from 'react';
import { Link, NavLink, Route, Routes } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import Portfolio from '../Portfolio';
import LegalNotices from './LegalNotices';
import About from '../About';
import Contact from '../Contact';
import Prestations from '../Prestations';
import PrivacyPolicy from './PrivacyPolicy';
import logo from '../../logo.svg';
import mobileLogo from '../../mobile-logo.svg';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(faBars);

const Menu = (props)=>{

    const [displayNav,setDisplayNav] = useState(false);
    const handleNavMobile = ()=>{
        setDisplayNav(!displayNav)
    }
    const collapsed = displayNav && 'collapsed' ;


    return (
        <div>
            <header className="App-header">
                <nav className='gyo-navigation'>
                    <figure className='logo only-desktop'>
                        <Link to='/'><img src={logo}  width='300' title='www.gyo-webgraphiste.com' alt='www.gyo-webgraphiste.com'/></Link>
                    </figure>
                    <figure className='mobile-logo mobile'>
                        <Link to='/'><img src={mobileLogo}  width='200' title='www.gyo-webgraphiste.com' alt='www.gyo-webgraphiste.com'/></Link>
                    </figure>
                    <i onClick={handleNavMobile} className="fa fa-2x fa-bars icon-hamburger" aria-hidden="true">
                    <FontAwesomeIcon icon="fa-bars" /></i>
                    <div className={`nav-block ${collapsed}`}>
                        <ul className='gyo-navbar'>
                            <li><NavLink  onClick={handleNavMobile} to='/'>prestations</NavLink></li>
                            <li><NavLink  onClick={handleNavMobile} to='/about'>à propos</NavLink></li>
                            <li><NavLink  onClick={handleNavMobile} to='/portfolio'>portfolio</NavLink></li>
                            <li><NavLink  onClick={handleNavMobile} to='/contact'>contact</NavLink></li>
                        </ul>
                    </div>
                </nav>
            </header>
            <Routes>
                <Route exact path = '/' element={<Prestations/>}></Route>
                <Route path = '/about' element={<About/>}></Route>
                <Route path = '/portfolio' element={<Portfolio/>}></Route>
                <Route path = '/contact' element={<Contact/>}></Route>
                <Route path = '/legal-notices' element={<LegalNotices/>}></Route>
                <Route path = '/privacy-policy' element={<PrivacyPolicy/>}></Route>
                <Route exact path = '*' element={<ErrorPage/>}></Route>
            </Routes>
        </div>
        
    );
}

export default Menu;