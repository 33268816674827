import React from "react";
import { Link } from "react-router-dom";

const Footer = ()=>{

    return(

            <footer className="gyo-footer">
            <p> &copy;gyo-webgraphiste.com - { new Date().getFullYear()} - Tous droits r&eacute;serv&eacute;s - <Link to="/legal-notices" >Mentions l&eacute;gales</Link> - <Link to="/privacy-policy"> Vie privéee et Politique de confidentialité</Link></p>
            </footer>

    )
}
export default Footer;