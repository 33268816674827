import React, { useState } from 'react';

const  Contact = (props)=> {

    const  dataForm={
        nom:'',
        prenom:'',
        email:'',
        subject:'',
        message:'',
        phone:'',
        company:''
    }
  
    const [contactForm,setContactForm] = useState(dataForm);
    const [notification,setNotification] = useState({});
    const [errorList,setErrorList] = useState([]);
    const regexpEmail =/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const handleChange = (e)=>{
        setContactForm({...contactForm, [e.target.name]:e.target.value})
        setErrorList([]);
    }

    const handleSubmit= (e)=>{
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' ,mode:'no-cors','Accept': 'application/json'},
            body: JSON.stringify(contactForm)
        };

        const errs = checkvalues({ nom, prenom,email,subject, message, phone,company});
        if(errs.length){
            setErrorList(errs)
            return false;   
        }

        fetch('formulaire.php',requestOptions)
        .then(response => response.json())
        .then(response => {
            if (response){
                setNotification(response);
            }
            setTimeout(function(){
                setErrorList([]);
                setNotification({});
                setContactForm(dataForm);
            }
            ,2000);
        })
        .catch(err=>console.log(err))
        
    }

    const reset = (e)=>{
        e.preventDefault();
        setContactForm(dataForm);
    }

    const checkvalues = ({ nom, prenom,email,subject, message, phone,company})=>{
        const errors =[];
  
        if(!regexpEmail.test(email)){
            errors.push({
                msg:" Votre email n'est pas valide",
                alias:"email"
            })
        }
        if(isNaN(phone)){
    
            errors.push({
                msg:" Votre numéro de telephone n'est pas valide",
                alias:"phone"
            });
        }
        if(message===''){
    
            errors.push({
                msg:" Votre message est vide",
                alias:"message"
            })
        }
        return errors;
    }

    const { nom, prenom,email,subject, message, phone,company} = contactForm;

    const notif =Object.keys(notification).length !==0 ?<p className={`${notification.nameClass}`}>{notification.msg}</p> :''
    
    return (
        <section className="form">

            <h1>Contact</h1>

            <form id="form1" method="post" action="formulaire.php" onSubmit={handleSubmit}><br/>
                <div id ="notifications">
                {errorList.length>0 && errorList.map((err,k)=>{
                    return <p key={k}>{err.msg}</p>
                })}

                {notif}
            </div>
                <div className="gyo-form-control">
                    <label htmlFor="prenom">Nom:</label>
                    <input name="nom" type="text"  autoComplete="off" required onChange={handleChange} value={nom}/>
                </div>
                <div className="gyo-form-control">
                    <label htmlFor="prenom">Prénom:</label>
                    <input name="prenom" type="text"  autoComplete="off" required onChange={handleChange} value={prenom}/>
                </div>
                <div className="gyo-form-control">
                    <label htmlFor="phone">Téléphone:</label>
                    <input name="phone" maxLength="10" type="phone" autoComplete="off" required onChange={handleChange} value={phone}/>
                </div>
                <div className="gyo-form-control">
                    <label htmlFor="company">Société:</label>
                    <input name="company"  type="text" onChange={handleChange}  autoComplete="off" value={company}/>
                </div>
                <div className="gyo-form-control">
                    <label htmlFor="email">Mail:</label>
                    <input name="email"  type="text" required onChange={handleChange}  autoComplete="off" value={email}/>
                </div>
                <div className="gyo-form-control">
                    <label htmlFor="subject">Votre demande:</label>
                    <select name="subject" required="required" onChange={handleChange}  value={subject} >
                        <option value="" disabled hidden>Motif</option>
                        <option value='devis'>Devis</option>
                        <option value='rdv'>Rendez-vous</option>
                        <option value='Renseignement/Information/Autre'>Renseignement/Information/Autre</option>
                    </select>
                </div>
                <div className="gyo-form-control">
                    <label htmlFor="txt">Votre message</label>
                    <textarea id="txt" name="message" cols="40" rows="6" placeholder="Saisissez votre message" required onChange={handleChange} value={message}></textarea>
                </div>
                <div className="gyo-form-control">
                    <input value="Envoyer" className="send-button btn" type="submit" required="required"/>
                    <input value="Effacer"  className="reset-button "type="reset" onClick={reset}/>
                </div>
            </form>
        </section>
    );
}

export default Contact;